import Header from "../components/Header";

import { useSiteData } from "../SiteDataContext";

import Footer from "../components/Footer";

import starImg from "../assets/img/star.png";
import starBasicImg from "../assets/img/star_basic.png";
import shieldImg from "../assets/img/shield.png";
import questionImg from "../assets/img/question.png";
import Modal from "../components/Modal";

function Main() {
  const { siteData } = useSiteData();

  return (
    <div className="bg-white dark:bg-dark mainpage">
      <Header />

      <section className="main-screen lg:pt-56 lg:pb-64 xs:pt-16 xs:pb-16  w-full bg-lightBlue overflow-hidden relative">
        <div className="container mx-auto max-w-7xl relative">
          <img
            src={starImg}
            alt=""
            className="zvezda lg:absolute block md:relative lg:max-w-screen-xl xs:hidden md:block md:max-w-80 md:pb-8"
          />
          <img
            src={starImg}
            alt=""
            className="zvezda lg:absolute block md:relative lg:max-w-screen-xl md:hidden md:pb-8 xs:max-w-80"
          />
          <h1 className="lg:text-[80px] xs:text-[32px] sm:text-[48px] xs:font-extrabold md:pb-10 xs:pb-4 text-dark">
            {siteData?.main?.title}
          </h1>
          <div className="font-semibold max-w-[920px] md:text-c2rem xs:text-xl md:leading-10">
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: siteData?.main?.content,
              }}
            />
          </div>

          <div className="flex md:gap-11 xs:gap-5 pt-16 md:flex-row xs:flex-col">
            <a
              href="/rating"
              className="button block bg-dark text-white text-2xl font-extrabold leading-0 rounded-lg text-center  md:py-8 md:px-16 xs:py-3 xs:px-3"
            >
              Рейтинг VPN
            </a>
            <a
              href=""
              className="button block bg-transparent border-[3px] border-dark text-dark text-2xl font-extrabold leading-0 rounded-lg text-center  md:py-8 md:px-16 xs:py-3 xs:px-3"
            >
              Параметры оценивания
            </a>
          </div>
        </div>
      </section>

      <section className="pt-16 pb-24">
        <div className="container max-w-screen-special mx-auto">
          <div className="flex justify-between items-center md:gap-16 xs:gap-4 xs:flex-col md:flex-row">
            <div className="md:w-2/3 xs:w-full md:order-1 xs:order-2">
              <div className="md:text-5xl xs:text-3xl font-extrabold text-dark pb-5">
                {siteData?.best_vpn?.title}
              </div>
              <div className="text-dark md:text-2xl xs:text-xl pb-9">
                <div
                  dangerouslySetInnerHTML={{
                    __html: siteData?.best_vpn?.content,
                  }}
                />
              </div>
              <a
                href="/rating#rating-section-1"
                className="button inline-block bg-pine text-white text-2xl font-extrabold leading-0 rounded-lg text-center  md:py-8 md:px-16 xs:py-3 xs:px-3"
              >
                Посмотреть рейтинг
              </a>
            </div>
            <div className="md:w-1/3 xs:w-full xs:order-1 md:order-2">
              <img
                src={starBasicImg}
                alt=""
                className="xs:max-w-32 md:max-w-[600px]"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-16 pb-24">
        <div className="container max-w-screen-special mx-auto">
          <div className="flex justify-between items-center md:gap-16 xs:gap-4 xs:flex-col md:flex-row">
            <div className="md:w-1/3 xs:w-full">
              <img
                src={shieldImg}
                alt=""
                className="xs:max-w-32 md:max-w-[600px]"
              />
            </div>
            <div className="md:w-2/3 xs:w-full">
              <div className="md:text-5xl xs:text-3xl font-extrabold text-dark pb-5">
                {siteData?.good_vpn?.title}
              </div>
              <div className="text-dark md:text-2xl xs:text-xl  pb-9">
                <div
                  dangerouslySetInnerHTML={{
                    __html: siteData?.good_vpn?.content,
                  }}
                />
              </div>
              <a
                href="/rating#rating-section-2"
                className="button inline-block bg-pine text-white text-2xl font-extrabold leading-0 rounded-lg text-center  md:py-8 md:px-16 xs:py-3 xs:px-3"
              >
                Посмотреть рейтинг
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="md:pt-16 md:pb-48 xs:pt-16 xs:pb-16">
        <div className="container max-w-screen-special mx-auto">
          <div className="flex justify-between items-center md:gap-16 xs:gap-4 xs:flex-col md:flex-row">
            <div className="md:w-2/3 xs:w-full md:order-1 xs:order-2">
              <div className="md:text-5xl xs:text-3xl font-extrabold text-dark pb-5">
                {siteData?.other_vpn?.title}
              </div>
              <div className="text-dark md:text-2xl xs:text-xl  pb-9">
                <div
                  dangerouslySetInnerHTML={{
                    __html: siteData?.other_vpn?.content,
                  }}
                />
              </div>
              <a
                href="/rating#rating-section-3"
                className="button inline-block bg-transparent border-[3px] border-dark text-dark text-2xl font-extrabold leading-0 rounded-lg text-center  md:py-8 md:px-16 xs:py-3 xs:px-3"
              >
                Посмотреть рейтинг
              </a>
            </div>
            <div className="md:w-1/3 xs:w-full xs:order-1 md:order-2">
              <img
                src={questionImg}
                alt=""
                className="xs:max-w-32 md:max-w-[600px]"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="main-screen md:pt-56 md:pb-64 xs:pt-16 xs:pb-16 w-full bg-lightBlue">
        <div className="container mx-auto max-w-7xl">
          <h2 className="md:text-6xl xs:text-4xl font-extrabold text-dark pb-3">
            {siteData?.our_criteria?.title}
          </h2>

          <div
            dangerouslySetInnerHTML={{
              __html: siteData?.our_criteria?.content,
            }}
          />

          {/* <div className="flex items-center md:justify-center xs:justify-start w-full">
            <a className="bg-darken rounded-lg cursor-pointer text-white font-extrabold inline-block button lg:py-8 lg:px-20 xs:px-4 xs:py-4 text-2xl">
              Узнать детальнее
            </a>
          </div> */}
        </div>
      </section>

      <Footer />
      <Modal />
    </div>
  );
}

export default Main;
