import React, { useEffect } from "react";
import { useSiteData } from "../SiteDataContext";

import closeIcon from "../assets/img/icons/closeIcon.svg";

function Modal() {
  const { siteData, setSiteData } = useSiteData();

  const close = () => {
    setSiteData({ ...siteData, isOpenModal: false });
  };

  const handleBackgroundClick = (e) => {
    if (e.target.id === "contact-modal") {
      close();
    }
  };

  return (
    <div
      id="contact-modal"
      className={`modal-container p-5 md:p-2 flex justify-center items-center ${siteData.isOpenModal ? "" : "hidden"
        }`}
      onClick={handleBackgroundClick}
    >

     

      <div className="modal-sub-container overflow-auto p-5">


      <div
        className="modal-close inline absolute r-8 t-8 p-0-5 cursor-pointer"
        onClick={close}
      >
        <img className="w-1-125" src={closeIcon} />
      </div>
      

        <article className="f-size-1">
          <div
            className="ck-content"
            dangerouslySetInnerHTML={{
              __html: siteData?.contact?.content,
            }}
          />
        </article>
      </div>
    </div>
  );
}

export default Modal;
